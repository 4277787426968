<template>
  <div class="main-card h-100 gap-20 d-flex flex-column">
    <!-- Employee Details -->
    <CRow class="bodyItem gap-10">
      <CCol col="12" class="bodyItemValue y-center-g-5">
        <img
          :src="employeeImage"
          @error="() => (employeeImage = 'https://picsum.photos/200')"
          class="employeeImage"
          alt="Employee Image"
        />
        <h4>{{ employee.fullName }}</h4>
      </CCol>
      <CCol col="12" class="bodyItemValue">
        <span>شماره همراه :</span>
        <span>{{ employee.phoneNumber }}</span>
      </CCol>
      <CCol col="12" class="bodyItemValue">
        <span>تاریخ ثبت‌نام :</span>
        <span>{{ employee.registrationDate }}</span>
      </CCol>
      <CButton
          color="secondary"
          class="specialistItemBtn"
        >
          <span>ارسال درخواست همکاری</span>
        </CButton>
    </CRow>
  </div>
</template>

<script>
export default {
  name: "EmployeeCard",
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      employeeImage: 'https://picsum.photos/200', // Using Lorem Picsum for sample images
    };
  },
  methods: {
    getStatusClass(status) {
      return status === 1
        ? 'text-success'
        : status === 0
          ? 'text-warning'
          : 'text-danger';
    },
    getStatusLabel(status) {
      const statusOptions = [
        { value: '', label: 'همه' },
        { value: 0, label: 'در حال بررسی' },
        { value: 1, label: 'تایید شده' },
      ];
      const statusOption = statusOptions.find(option => option.value === status);
      return statusOption ? statusOption.label : '';
    },
  },
};
</script>

<style scoped>
.bodyItemValue {
  width: 100%;
}

.bodyItemValue h4 {
  font-size: 1rem;
  padding-right: 4px;
}

.employeeImage {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
}

.text-success {
  color: #28a745;
}

.text-warning {
  color: #ffc107;
}

.text-danger {
  color: #dc3545;
}

.specialistItemBtn{
  width: 100%;
  padding: 5px 0px;
}
</style>
