<template>
  <div class="tab-body-card" style="height: calc(100vh - 10vh)">
    <!-- Filters Section -->
    <CRow class="mb-4 gap-3">
      <!-- Search Field -->
      <CCol md="4">
        <CInput
          v-model="searchQuery"
          placeholder="جستجو بر اساس نام یا شماره تلفن"
        />
      </CCol>
    </CRow>

    <!-- No Data -->
    <no-data
      v-if="filteredEmployees.length === 0"
      :show="true"
      message="هیچ کارمندی یافت نشد"
      class="emptyTitle"
    />

    <!-- Display Farm Employees as Cards -->
    <div v-else>
      <CRow class="farm-employees-list">
        <CCol
          md="3"
          class="mb-4"
          v-for="(employee, index) in filteredEmployees"
          :key="index"
        >
          <EmployeeCard :employee="employee" />
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import EmployeeCard from "./EmployeeCard.vue";
import "vue-select/dist/vue-select.css";
import NoData from "../../../components/no-data.vue";
import { mapActions } from "vuex";

export default {
  name: "FarmEmployeesTab",
  components: {
    NoData,
    EmployeeCard,
  },
  data() {
    return {
      searchQuery: "",
      employeesList: [],
    };
  },
  computed: {
    filteredEmployees() {
      return this.employeesList.filter((employee) => {
        return (
          this.searchQuery === "" ||
          employee.fullName.includes(this.searchQuery) ||
          employee.phoneNumber.includes(this.searchQuery)
        );
      });
    },
  },
  methods: {
    ...mapActions("farmerColleagues", ["GetAllFarmuser"]),

    async fetchData() {
      let result = await this.GetAllFarmuser({
        searchText: this.searchQuery,
      });
      if (result.succeeded) {
        result.data.forEach((element) => {
          this.employeesList.push({
            id: element.farmuserId,
            fullName: `${element.name} ${element.lastName}`,
            phoneNumber: element.phone,
            registrationDate: element.registerDateTime,
          });
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: result.message,
          type: "error",
        });
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.farm-employees-list {
  width: 100%;
  height: calc(100vh - 25vh);
  overflow-y: auto;
}
.emptyTitle {
  text-align: center;
  font-size: 1.2rem;
  color: var(--secondary);
}
</style>
